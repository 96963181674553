import g1 from "../../assets/img/gallery_images/g1.jpg";
import g2 from "../../assets/img/gallery_images/g2.jpg";
import g3 from "../../assets/img/gallery_images/g3.jpg";
import g4 from "../../assets/img/gallery_images/g4.jpg";
import g5 from "../../assets/img/gallery_images/g5.jpg";
import g6 from "../../assets/img/gallery_images/g6.jpg";


const GalleryData = [
    {
        id: 1,        
        img: g1,
        altImg: "No Img Found",        
        isVideo:false,

    },
    {
        id: 2,        
        img: g2,
        altImg: "No Img Found",        
        isVideo:false,

    },
    {
        id: 3,        
        img: g3,
        altImg: "No Img Found",        
        isVideo:false,

    },
    {
        id: 4,        
        img: g4,
        altImg: "No Img Found",        
        isVideo:false,

    },
    {
        id: 5,        
        img: g5,
        altImg: "No Img Found",        
        isVideo:false,

    },
    {
        id: 6,        
        img: g6,
        altImg: "No Img Found",        
        isVideo:false,
    },    
    {
        id: 7,        
        img: 'ohr_Vex4m4c',
        altImg: "No Img Found",        
        isVideo:true,

    },
    {
        id: 7,        
        img: 'MKOd5AZnEX0',
        altImg: "No Img Found",        
        isVideo:true,

    },

]

export default GalleryData;