import React from "react";

function PrivacyPolicy() {
  return (
    <div className="p-6 ml-5">
      <div className="mb-8 flex justify-center">
        <p className="font-bold text-2xl">Privacy Policy</p>
      </div>
      <div className="mb-6">
        <p className="font-semibold">Last updated: September 25, 2024</p>
        <p className="mt-4">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p className="mt-2">
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">
          Interpretation and Definitions
        </h2>
        <h3 className="font-semibold mt-4">Interpretation</h3>
        <p className="mt-2">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h3 className="font-semibold mt-4">Definitions</h3>
        <p className="mt-2">For the purposes of this Privacy Policy:</p>
        <ul className="list-disc list-inside mt-2">
          <li>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </li>
          <li>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </li>
          <li>
            <strong>Company</strong> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Thk International
            trading.
          </li>
          <li>
            <strong>Cookies</strong> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
          </li>
          <li>
            <strong>Country</strong> refers to: Oman.
          </li>
          <li>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </li>
          <li>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </li>
          <li>
            <strong>Service</strong> refers to the Website.
          </li>
          <li>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company.
          </li>
          <li>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself.
          </li>
          <li>
            <strong>Website</strong> refers to Thk International trading,
            accessible from{" "}
            <a href="https://thktrade.com/" className="text-blue-500">
              https://thktrade.com/
            </a>
          </li>
          <li>
            <strong>You</strong> means the individual accessing or using the
            Service.
          </li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">
          Collecting and Using Your Personal Data
        </h2>
        <h3 className="font-semibold mt-4">Types of Data Collected</h3>
        <h4 className="font-semibold mt-4">Personal Data</h4>
        <p className="mt-2">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul className="list-disc list-inside mt-2">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>

        <h4 className="font-semibold mt-4">Usage Data</h4>
        <p className="mt-2">
          Usage Data is collected automatically when using the Service.
        </p>
        <p className="mt-2">
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p className="mt-2">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p className="mt-2">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">
          Tracking Technologies and Cookies
        </h2>
        <p className="mt-2">
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <h3 className="font-semibold mt-4">Cookies Browser Cookies</h3>
        <p className="mt-2">
          A cookie is a small file placed on Your Device. You can instruct Your
          browser to refuse all Cookies or to indicate when a Cookie is being
          sent. However, if You do not accept Cookies, You may not be able to
          use some parts of our Service. . Unless you have adjusted Your browser
          setting so that it will refuse Cookies, our Service may use Cookies.
        </p>
        <h3 className="font-semibold mt-4">Web Beacons</h3>
        <p className="mt-2">
          Certain sections of our Service and our emails may contain small
          electronic files known as web beacons (also referred to as clear gifs,
          pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages or opened an
          email and for other related website statistics (for example, recording
          the popularity of a certain section and verifying system and server
          integrity).
        </p>
        <p className="mt-4 mb-4">
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
          remain on Your personal computer or mobile device when You go offline,
          while Session Cookies are deleted as soon as You close Your web
          browser. Learn more about cookies on the Privacy Policies website
          article.
        </p>
        <div class="mb-4">
          <h2 class="text-xl font-semibold">Necessary / Essential Cookies</h2>
          <p class="text-gray-700">
            <strong>Type:</strong> Session Cookies
          </p>
          <p class="text-gray-700">
            <strong>Administered by:</strong> Us
          </p>
          <p class="text-gray-700">
            <strong>Purpose:</strong> These Cookies are essential to provide You
            with services available through the Website and to enable You to use
            some of its features. They help to authenticate users and prevent
            fraudulent use of user accounts. Without these Cookies, the services
            that You have asked for cannot be provided, and We only use these
            Cookies to provide You with those services.
          </p>
        </div>

        <div class="mb-4">
          <h2 class="text-xl font-semibold">
            Cookies Policy / Notice Acceptance Cookies
          </h2>
          <p class="text-gray-700">
            <strong>Type:</strong> Persistent Cookies
          </p>
          <p class="text-gray-700">
            <strong>Administered by:</strong> Us
          </p>
          <p class="text-gray-700">
            <strong>Purpose:</strong> These Cookies identify if users have
            accepted the use of cookies on the Website.
          </p>
        </div>

        <div class="mb-4">
          <h2 class="text-xl font-semibold">Functionality Cookies</h2>
          <p class="text-gray-700">
            <strong>Type:</strong> Persistent Cookies
          </p>
          <p class="text-gray-700">
            <strong>Administered by:</strong> Us
          </p>
          <p class="text-gray-700">
            <strong>Purpose:</strong> These Cookies allow us to remember choices
            You make when You use the Website, such as remembering your login
            details or language preference. The purpose of these Cookies is to
            provide You with a more personal experience and to avoid You having
            to re-enter your preferences every time You use the Website.
          </p>
        </div>

        <p class="text-gray-700 mt-4">
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">Use of Your Personal Data</h2>
        <p className="mt-2">
          The Company may use Personal Data for the following purposes:
        </p>
        <ul className="list-disc list-inside mt-2">
          <li>
            <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service.
          </li>
          <li>
            <strong>To manage Your Account:</strong> to manage Your registration
            as a user of the Service.
          </li>
          <li>
            <strong>For the performance of a contract:</strong> the development,
            compliance and undertaking of the purchase contract for the
            products, items or services.
          </li>
          <li>
            <strong>To contact You:</strong> To contact You by email, telephone
            calls, SMS, or other forms of electronic communication.
          </li>
          <li>
            <strong>To provide You</strong> with news, special offers and
            general information about other goods, services, and events.
          </li>
          <li>
            <strong>To manage Your requests:</strong> To attend and manage Your
            requests to Us.
          </li>
          <li>
            <strong>For business transfers:</strong> We may use Your information
            to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred.
          </li>
          <li>
            <strong>For other purposes: </strong> We may use Your information
            for other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </li>
        </ul>
        <p className="mt-3">
          We may share Your personal information in the following situations:
        </p>
        <ul className="list-disc list-inside mt-2">
          <li>
            <strong>With Service Providers: </strong>We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </li>
          <li>
            <strong>For business transfers: </strong>We may share or transfer
            Your personal information in connection with, or during negotiations
            of, any merger, sale of Company assets, financing, or acquisition of
            all or a portion of Our business to another company.
          </li>
          <li>
            <strong>With Affiliates: </strong>We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </li>
          <li>
            <strong>With business partners: </strong>We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </li>
          <li>
            <strong>With other users: </strong>when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </li>
          <li>
            <strong>With Your consent: </strong> We may disclose Your personal
            information for any other purpose with Your consent.
          </li>
        </ul>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">
          Retention of Your Personal Data
        </h2>
        <p className="mt-2">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p className="mt-2">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">
          Transfer of Your Personal Data
        </h2>
        <p className="mt-2">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p className="mt-2">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p className="mt-2">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">Delete Your Personal Data</h2>
        <p className="mt-2">
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </p>
        <p className="mt-2">
          Our Service may give You the ability to delete certain information
          about You from within the Service
        </p>
        <p className="mt-2">
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </p>
        <p className="mt-2">
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">
          Disclosure of Your Personal Data
        </h2>
        <h3 className="text-lg font-medium text-gray-800 mt-4 mb-2">
          Business Transactions
        </h3>
        <p className="mt-2">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h3 className="text-lg font-medium text-gray-800 mt-4 mb-2">
          Law enforcement
        </h3>
        <p className="mt-2">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h3 className="text-lg font-medium text-gray-800 mt-4 mb-2">
          Other legal requirements
        </h3>
        <p className="mt-2">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul className="list-disc list-inside mt-2">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <h3 className="text-lg font-medium text-gray-800 mt-4 mb-2">
          Security of Your Personal Data
        </h3>
        <p className="mt-2">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h3 className="text-lg font-medium text-gray-800 mt-4 mb-2">
          Detailed Information on the Processing of Your Personal Data
        </h3>
        <p className="mt-2">
          The Service Providers We use may have access to Your Personal Data.
          These third-party vendors collect, store, use, process and transfer
          information about Your activity on Our Service in accordance with
          their Privacy Policies.
        </p>
        <h3 className="text-lg font-medium text-gray-800 mt-4 mb-2">
          Usage, Performance and Miscellaneous
        </h3>
        <p className="mt-2">
          We may use third-party Service Providers to maintain and improve our
          Service.
        </p>
        <h4 class="text-base font-semibold text-gray-700 mt-3 mb-2">
          Google Places
        </h4>
        <p className="mt-2">
          Google Places is a service that returns information about places using
          HTTP requests. It is operated by Google.
        </p>
        <p className="mt-2">
          Google Places service may collect information from You and from Your
          Device for security purposes.
        </p>
        <p className="mt-2">
          The information gathered by Google Places is held in accordance with
          the Privacy Policy of Google:
          https://www.google.com/intl/en/policies/privacy/
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">Children's Privacy</h2>
        <p className="mt-2">
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </p>
        <p className="mt-2">
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">Links to Other Websites</h2>
        <p className="mt-2">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p className="mt-2">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">
          Changes to this Privacy Policy
        </h2>
        <p className="mt-2">
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="mt-2">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </p>
        <p className="mt-2">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </div>

      <div className="mb-6">
        <h2 className="font-semibold text-xl">Contact Us</h2>
        <p className="mt-2">
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul className="list-disc list-inside mt-2">
          <li>
            By email:{" "}
            <a href="mailto:tariqkhan@thktrade.com" className="text-blue-500">
            contact@thktrade.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
