// import img_1 from "../../assets/img/img_1.jpg";
// import img_2 from "../../assets/img/img_2.jpg";
// import img_3 from "../../assets/img/img_3.jpg";
// import img_4 from "../../assets/img/img_4.jpg";
// import img_5 from "../../assets/img/img_5.jpg";
// import img_6 from "../../assets/img/img_6.jpg";
// import img_7 from "../../assets/img/img_7.jpg";
// import img_8 from "../../assets/img/img_8.jpg";
// import img_9 from "../../assets/img/img_9.jpg";
// import img_10 from "../../assets/img/img_10.jpg";
// import img_11 from "../../assets/img/img_11.jpg";
// import img_12 from "../../assets/img/img_12.jpg";
// import img_13 from "../../assets/img/img_13.jpg";
// import img_14 from "../../assets/img/img_14.jpg";
// import saddle_1 from "../../assets/img/Saddle/1000036961.jpg";
import saddle_2 from "../../assets/img/Saddle/1000036962.jpg";
import saddle_3 from "../../assets/img/Saddle/1000036964.jpg";
import saddle_4 from "../../assets/img/Saddle/1000036965.jpg";
import saddle_5 from "../../assets/img/Saddle/1000036974.jpg";
import saddle_6 from "../../assets/img/Saddle/1000036976.png";
import saddle_7 from "../../assets/img/Saddle/1000036984.jpg";
import saddle_8 from "../../assets/img/Saddle/1000036985.jpeg";
import saddle_9 from "../../assets/img/Saddle/1000036986.jpeg";
import saddle_pad_1 from "../../assets/img/Saddle_pads/1000036966.jpg";
import saddle_pad_2 from "../../assets/img/Saddle_pads/1000036968.jpg";
import saddle_pad_3 from "../../assets/img/Saddle_pads/1000036969.jpg";
import saddle_pad_4 from "../../assets/img/Saddle_pads/1000036970.jpg";
import saddle_pad_5 from "../../assets/img/Saddle_pads/1000036977.png";
import saddle_pad_6 from "../../assets/img/Saddle_pads/1000036983.jpg";
import saddle_pad_7 from "../../assets/img/Saddle_pads/1000036984.jpeg";
import saddle_pad_8 from "../../assets/img/Saddle_pads/1000036985.jpeg";
import saddle_pad_9 from "../../assets/img/Saddle_pads/1000036986.jpeg";
import saddle_pad_10 from "../../assets/img/Saddle_pads/1000036987.jpeg";
import saddle_pad_11 from "../../assets/img/Saddle_pads/1000036988.jpeg";
import saddle_pad_12 from "../../assets/img/Saddle_pads/1000036989.jpeg";
import saddle_pad_13 from "../../assets/img/Saddle_pads/1000036990.jpeg";
import saddle_pad_14 from "../../assets/img/Saddle_pads/1000036991.jpeg";
// Bridle products
import bridle_1 from "../../assets/img/bridle/bridle1.png";
import bridle_2 from "../../assets/img/bridle/bridle2.jpg";
import bridle_3 from "../../assets/img/bridle/bridle3.jpg";
import bridle_4 from "../../assets/img/bridle/bridle4.jpg";
// Halter Products
import halter_1 from "../../assets/img/Halter/Halter1.jpg";
import halter_2 from "../../assets/img/Halter/Halter2.jpg";
import halter_3 from "../../assets/img/Halter/Halter3.jpg";
import halter_4 from "../../assets/img/Halter/Halter4.jpg";
import halter_5 from "../../assets/img/Halter/Halter5.jpg";
import halter_6 from "../../assets/img/Halter/Halter6.jpg";
import halter_7 from "../../assets/img/Halter/Halter7.jpg";
import halter_8 from "../../assets/img/Halter/Halter8.jpg";
import Stirrup_1 from "../../assets/img/Stirrups/stirrup1.jpg";
import Stirrup_2 from "../../assets/img/Stirrups/stirrup2.jpg";
import Stirrup_3 from "../../assets/img/Stirrups/stirrup3.jpg";
// lead ropes
import lead_rope_1 from "../../assets/img/lead_ropes/Leadropes1.jpg";
import lead_rope_2 from "../../assets/img/lead_ropes/Leadropes2.jpg";
import lead_rope_3 from "../../assets/img/lead_ropes/Leadropes3.jpg";
import lead_rope_4 from "../../assets/img/lead_ropes/Leadropes4.jpg";
import lead_rope_5 from "../../assets/img/lead_ropes/Leadropes5.jpg";
import lead_rope_6 from "../../assets/img/lead_ropes/Leadropes6.jpg";
import lead_rope_7 from "../../assets/img/lead_ropes/Leadropes7.jpg";
import lead_rope_8 from "../../assets/img/lead_ropes/Leadropes8.jpg";
import lead_rope_9 from "../../assets/img/lead_ropes/Leadropes9.jpg";
// riding Btrrches
import riding_breeches_1 from "../../assets/img/riding_breeches/RidingBreeches1.jpg";
import riding_breeches_2 from "../../assets/img/riding_breeches/RidingBreeches2.jpg";
import riding_breeches_3 from "../../assets/img/riding_breeches/RidingBreeches3.jpg";
import riding_breeches_4 from "../../assets/img/riding_breeches/RidingBreeches4.jpg";
import riding_breeches_5 from "../../assets/img/riding_breeches/RidingBreeches5.jpg";
import riding_breeches_6 from "../../assets/img/riding_breeches/RidingBreeches6.jpg";
import riding_breeches_7 from "../../assets/img/riding_breeches/RidingBreeches7.jpg";
import riding_breeches_8 from "../../assets/img/riding_breeches/RidingBreeches8.jpg";
import riding_breeches_9 from "../../assets/img/riding_breeches/RidingBreeches9.jpg";
import riding_breeches_10 from "../../assets/img/riding_breeches/RidingBreeches10.jpg";
import riding_breeches_11 from "../../assets/img/riding_breeches/RidingBreeches11.jpg";
// Chap Products
import chap_1 from "../../assets/img/chaps/Chaps1.jpeg";
import chap_2 from "../../assets/img/chaps/Chaps2.jpeg";
import chap_3 from "../../assets/img/chaps/Chaps3.jpeg";
import chap_4 from "../../assets/img/chaps/Chaps4.jpeg";
import chap_5 from "../../assets/img/chaps/Chaps5.jpeg";
import chap_6 from "../../assets/img/chaps/Chaps6.jpeg";
import chap_7 from "../../assets/img/chaps/Chaps7.jpeg";
import chap_8 from "../../assets/img/chaps/Chaps8.jpeg";


import horse_rug_1 from "../../assets/img/horse_rugs/IMG-20240927-WA0034.jpg";
import horse_rug_2 from "../../assets/img/horse_rugs/IMG-20240927-WA0035.jpg";
import horse_rug_3 from "../../assets/img/horse_rugs/IMG-20240927-WA0036.jpg";
import horse_rug_4 from "../../assets/img/horse_rugs/IMG-20240927-WA0037.jpg";
import horse_rug_5 from "../../assets/img/horse_rugs/IMG-20240927-WA0038.jpg";
// Saddle Girth Products
import saddle_girth_1 from "../../assets/img/saddle_girth/saddlegirth1.jpg";
import saddle_girth_2 from "../../assets/img/saddle_girth/saddlegirth2.png";
import saddle_girth_3 from "../../assets/img/saddle_girth/saddlegirth3.jpg";
import saddle_girth_4 from "../../assets/img/saddle_girth/saddlegirth4.png";
import saddle_girth_5 from "../../assets/img/saddle_girth/saddlegirth5.jpg";
import saddle_girth_6 from "../../assets/img/saddle_girth/saddlegirth6.jpg";
import saddle_girth_7 from "../../assets/img/saddle_girth/saddlegirth7.jpg";
import saddle_girth_8 from "../../assets/img/saddle_girth/saddlegirth8.jpg";
import saddle_girth_9 from "../../assets/img/saddle_girth/saddlegirth9.jpg";
// martingale 
import martingale_1 from "../../assets/img/Martingelas/martingale1.jpg";
import martingale_2 from "../../assets/img/Martingelas/martingale2.jpg";
import martingale_3 from "../../assets/img/Martingelas/martingale3.jpg";
import martingale_4 from "../../assets/img/Martingelas/martingale4.jpg";
import martingale_5 from "../../assets/img/Martingelas/martingale5.jpg";
import martingale_6 from "../../assets/img/Martingelas/martingale6.jpg";
import martingale_7 from "../../assets/img/Martingelas/martingale7.jpg";
import martingale_8 from "../../assets/img/Martingelas/martingale8.jpg";
import martingale_9 from "../../assets/img/Martingelas/martingale9.jpg";

import grooming_equipment_1 from "../../assets/img/grooming_equipment/IMG-20240927-WA0059.jpg";
import grooming_equipment_2 from "../../assets/img/grooming_equipment/IMG-20240927-WA0060.jpg";
import grooming_equipment_3 from "../../assets/img/grooming_equipment/IMG-20240927-WA0061.jpg";
import grooming_equipment_4 from "../../assets/img/grooming_equipment/IMG-20240927-WA0062.jpg";
import grooming_equipment_5 from "../../assets/img/grooming_equipment/IMG-20240927-WA0063.jpg";
// Riding Boot
import riding_boot_1 from "../../assets/img/riding_boots/Ridingboots1.jpg";
import riding_boot_2 from "../../assets/img/riding_boots/Ridingboots2.jpg";
import riding_boot_3 from "../../assets/img/riding_boots/Ridingboots3.jpg";
import riding_boot_4 from "../../assets/img/riding_boots/Ridingboots4.jpeg";
import riding_boot_5 from "../../assets/img/riding_boots/Ridingboots5.jpg";
import riding_boot_6 from "../../assets/img/riding_boots/Ridingboots6.jpg";
import riding_boot_7 from "../../assets/img/riding_boots/Ridingboots7.jpg";
import riding_boot_8 from "../../assets/img/riding_boots/Ridingboots8.jpg";
import riding_boot_9 from "../../assets/img/riding_boots/Ridingboots9.jpeg";
import riding_boot_10 from "../../assets/img/riding_boots/Ridingboots10.jpeg";

import horse_boot_1 from "../../assets/img/horse_boots/IMG-20240927-WA0022.png";
import horse_boot_2 from "../../assets/img/horse_boots/IMG-20240927-WA0023.png";
import horse_boot_3 from "../../assets/img/horse_boots/IMG-20240927-WA0024.png";
import horse_boot_4 from "../../assets/img/horse_boots/IMG-20240927-WA0025 (1).png";

import gifts1 from "../../assets/img/gift_items/Gifts1.jpeg"

import general1 from "../../assets/img/general/General.jpeg"

const ProductData = [
    {
        id: 1,
        category: "Saddle",
        img: [saddle_8, saddle_9, saddle_2, saddle_3, saddle_4, saddle_5, saddle_6, saddle_7 ],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,

    },
    {
        id: 2,
        category: "Saddle Pad",
        img: [saddle_pad_1, saddle_pad_2, saddle_pad_3, saddle_pad_4, saddle_pad_5, saddle_pad_6, saddle_pad_7, saddle_pad_8, saddle_pad_9, saddle_pad_10, saddle_pad_11, saddle_pad_12, saddle_pad_13, saddle_pad_14],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,

    },
    // {
    //     id: 3,
    //     category: "Seat Saver",
    //     img: [img_3],
    //     altImg: "No Img Found",
    //     title: "Product Name required",
    //     isNewArrival:false,
    // },
    {
        id: 4,
        category: "Stirrup",
        img: [Stirrup_1, Stirrup_2, Stirrup_3],
        altImg: "No Img Found",
        title: "Riding apparel and footwear",
        isNewArrival:false,

    },
    {
        id: 5,
        category:"Bridle",
        img: [bridle_1, bridle_2, bridle_3, bridle_4],
        altImg: "No Img Found",
        title: "Whips and Spurs",
        isNewArrival:false,

    },
    {
        id: 6,
        category:"Martingales",
        img: [martingale_1, martingale_2, martingale_3, martingale_4, martingale_5, martingale_6, martingale_7, martingale_8, martingale_9],
        altImg: "No Img Found",
        title: "Bridles and reins",
        isNewArrival:false,

    },
    {
        id: 7,
        category:"Saddle Girth",
        img: [saddle_girth_1, saddle_girth_2, saddle_girth_3, saddle_girth_4, saddle_girth_5, saddle_girth_6, saddle_girth_7, saddle_girth_8, saddle_girth_9],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,

    },
    {
        id: 8,
        category:"Halter",
        img: [halter_1, halter_2, halter_3, halter_4, halter_5, halter_6, halter_7, halter_8],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    {
        id: 9,
        category:"Lead Rope",
        img: [lead_rope_1, lead_rope_2, lead_rope_3, lead_rope_4, lead_rope_5, lead_rope_6, lead_rope_7, lead_rope_8, lead_rope_9],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    // {
    //     id: 10,
    //     category:"Lunging Equipment",
    //     img: [],
    //     altImg: "No Img Found",
    //     title: "Product Name required",
    //     isNewArrival:false,
    // },
    {
        id: 11,
        category:"Horse Boots",
        img: [horse_boot_1, horse_boot_2, horse_boot_3, horse_boot_4],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    // {
    //     id: 12,
    //     category:"Helmet",
    //     img: [i],
    //     altImg: "No Img Found",
    //     title: "Product Name required",
    //     isNewArrival:false,
    // },
    {
        id: 13,
        category:"Horse Rugs",
        img: [horse_rug_1, horse_rug_2, horse_rug_3, horse_rug_4, horse_rug_5],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    // {
    //     id: 14,
    //     category:"Stable Equipment",
    //     img: [],
    //     altImg: "No Img Found",
    //     title: "Product Name required",
    //     isNewArrival:false,
    // },
    {
        id: 15,
        category:"Grooming Equipment",
        img: [grooming_equipment_1, grooming_equipment_2, grooming_equipment_3, grooming_equipment_4, grooming_equipment_5],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    // {
    //     id: 16,
    //     category:"Gloves",
    //     img: [],
    //     altImg: "No Img Found",
    //     title: "Product Name required",
    //     isNewArrival:false,
    // },
    {
        id:17,
        category:"Riding Boots",
        img: [riding_boot_1, riding_boot_2, riding_boot_3, riding_boot_4, riding_boot_5, riding_boot_6 ,riding_boot_7 ,riding_boot_8, riding_boot_9, riding_boot_10],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    // {
    //     id:18,
    //     category:"Long Boots",
    //     img: [],
    //     altImg: "No Img Found",
    //     title: "Product Name required",
    //     isNewArrival:false,
    // },
    {
        id:19,
        category:"Riding Breeches",
        img: [riding_breeches_1, riding_breeches_2, riding_breeches_3, riding_breeches_4, riding_breeches_5, riding_breeches_6, riding_breeches_7, riding_breeches_8, riding_breeches_9, riding_breeches_10, riding_breeches_11
            
        ],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    {
        id:20,
        category:"Chaps",
        img: [chap_1, chap_2, chap_3, chap_4, chap_5, chap_6, chap_7, chap_8],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    {
        id:21,
        category:"Gift items",
        img: [gifts1],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    {
        id:22,
        category:"General",
        img: [general1],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,
    },
    {
        id: 23,
        category: "New arrivals",
        img: [chap_1 ],
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,

    },

]

export default ProductData;